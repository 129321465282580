<template>
  <div>
    <section class="section is-main-section">
      <b-message title="Usuário Offline" type="is-danger" :closable="false">
        Usuários offline não podem acessar o chat! <br>
        Para acessar o chat, altere seu status para online ou ausente
      </b-message>
    </section>
  </div>
</template>

<script>

export default {
  name: 'UserOffline'
}
</script>

<style lang="scss" scoped>

</style>
